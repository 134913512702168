var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    staticClass: "noservice-image text-center pt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('img', {
    attrs: {
      "src": "img/illustration.png",
      "alt": "illustration"
    }
  })]), _c('b-col', {
    staticClass: "text-center pb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary btn-lg",
    on: {
      "click": _vm.locationPopup
    }
  }, [_vm._v(" Change Your Location ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }