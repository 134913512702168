<template>
<b-container>
  <b-row>
    <!-- <advertisement-top-banner /> -->
    <b-col md="12" class="noservice-image text-center pt-3">
      <img src="img/illustration.png" alt="illustration" />
    </b-col>
    <b-col md="12" class="text-center pb-3">
      <button class="btn btn-primary btn-lg" @click="locationPopup">
        Change Your Location
      </button>
    </b-col>
  </b-row>
  </b-container>
</template>

<script>
export default {
  name: "noservice",
  data() {
    return {};
  },
  methods: {
    locationPopup() {
      this.$bvModal.show("location-popup");
    },
  },
};
</script>
<style scoped>
.noservice-image img {
    padding-top: 20px;
}
@media (max-width: 768px) {
 .noservice-image img {
    width: 100%;
} 
}
</style>
