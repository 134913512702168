<template>
  <div v-if="this.deliveryAddress">
    <router-view> </router-view>
  </div>
  <div v-else>
    <noservice />
  </div>
</template>

<script>
import { mapState } from "vuex";
import noservice from "@/layouts/noservice";
export default {
  components: {
    noservice,
  },
  computed: {
    ...mapState({
      deliveryAddress: (state) => state.address.deliveryAddress,
    }),
  },
};
</script>
